import axios from 'axios';

const token = window.localStorage.getItem('token');

export const instance = axios.create({
    baseURL: 'https://maiseguidores.com/wp-json/wp/v2/clientes/',
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json' 
    },
});

export const getAllContacts = async (url) => {
    return await instance.get(url)
}

export const createContact = async (contact) => {
    const data = await instance.post('', contact);
    return data
}

export const updateContact = async (contact, config) => {
    return await instance.put(`${contact.id}`, config);
}
