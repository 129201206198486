import React, { useState, useCallback } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import style from './AddContact.css'

const AddContact = () => {
  const token = window.localStorage.getItem('token');
  let navigate = useNavigate()

  const [title, setTitle] = React.useState('');

  const [state,setState] = React.useState({
    clientes:{},
    isLoaded: false,
    content:''
  }) 


  const [mensage, setMensage] = React.useState('')

  function updateInput(event){
    setState({ ...state, clientes:{
      ...state.clientes,
    [event.target.name]: event.target.value
    }})
  }

  let handleClick =  useCallback (async(e) => {
    e.preventDefault();
    try{
      await axios.post(`https://maiseguidores.com/wp-json/wp/v2/clientes/`,{
        title: state?.clientes?.title,
        content: state?.content,
        excerpt: "opa",
        status: "publish",
        fields:{
          ...state.clientes
        },
        },{
          headers:{
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'
          }
        })

        navigate('/')

    }catch(e){
      console.log(e)
    }
    

  }, [state,title]);

  let handleAgain = useCallback(async (event) => {
      
      event.preventDefault();
      try {
        await axios.post(`https://maiseguidores.com/wp-json/wp/v2/clientes/`,{
        title: title,
        content: state?.content,
        excerpt: "opa",
        status: "publish",
        fields:{
          ...state.clientes
        },
        },{
          headers:{
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'
          }
        })
        setState({
          content: "",
          clientes:{
            email: "",
            plane: "",
            name: "",
            number: "",
            payment: "",
            start: "",
            status: "",
            title: ""
          },
          isLoaded: false
        })
        setMensage('Sucess in create user')

        setTimeout(()=>{
          setMensage('')
        }, 10000)

      } catch (error) {
        console.log(error);
        Navigate('contacts/add', { replace: false });
      } 
  })
  
  return (
    <React.Fragment>
      <section className="add-contact p-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="h4 text-success fw-bold">Create Contact</p>
              <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Observation: </label><label htmlFor="" className="EditContactWidth">
                <input
                    name="content"
                    value={state?.content}
                    onChange={(event)=> setState(prevState=> ({...prevState, content: event.target.value }))}
                    type="text"
                    className="form-control mb-5 mt-4"
                    placeholder="Content"
                  /></label></p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <form onSubmit={(event) => handleAgain(event)}>
                <div className="mb-2">
                  <p className="fst-italic list-group-item EditObservationContainer  "><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation ">Name: </label><label htmlFor="" className="EditContactWidth"><input
                      name="name"
                      value={state?.clientes?.name}
                      onChange={(event)=> updateInput(event)}
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    /></label></p>
                </div>
                <div className="mb-2">
                <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Number: </label><label htmlFor="" className="EditContactWidth"><input
                    name="number"
                    value={state?.clientes?.number}
                    onChange={(event)=> updateInput(event)}
                    type="text"
                    className="form-control"
                    placeholder="Number"
                  /></label></p>
                </div>
                <div className="mb-2">
                <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Email: </label><label htmlFor="" className="EditContactWidth"><input
                    name="email"
                    value={state?.clientes?.email}
                    onChange={(event)=> updateInput(event)}
                    type="text"
                    className="form-control"
                    placeholder="E-mail"
                  /></label></p>
                </div>
                <div className="mb-2">
                <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Plane: </label><label htmlFor="" className="EditContactWidth"><input
                    name="title"
                    value={state?.clientes?.title}
                    onChange={(event)=> updateInput(event)}
                    type="text"
                    className="form-control"
                    placeholder="Plane"
                  /></label></p>
                </div>
                <div className="mb-2">
                <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Start: </label><label htmlFor="" className="EditContactWidth"><input
                    name="start"
                    value={state?.clientes?.start}
                    onChange={(event)=> updateInput(event)}
                    type="text"
                    className="form-control"
                    placeholder="Start"
                  /></label></p>
                </div>
                <div className="mb-2">
                <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Payment: </label><label htmlFor="" className="EditContactWidth"><input
                    name="payment"
                    value={state?.clientes?.payment}
                    onChange={(event)=> updateInput(event)}
                    type="text"
                    className="form-control"
                    placeholder="payment"
                  /></label></p>
                </div>
                <div className="mb-2">
                <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Status: </label><label htmlFor="" className="EditContactWidth"><input
                    name="status"
                    value={state?.clientes?.status}
                    onChange={(event)=> updateInput(event)}
                    type="text"
                    className="form-control"
                    placeholder="Status"
                  /></label></p>
                </div>
                {mensage && (
                <div className='AddContactMensage'>
                  <p>{mensage}</p>
                </div>)}
                
                <div className="mb-2">
                  <input
                    type="submit"
                    onClick={handleClick}
                    className="btn btn-success "
                    value="Create"
                  />
                  <Link to={'/contacts/list'} className="btn btn-dark ms-2">
                    Cancel
                  </Link>
                  <input
                    type="submit"
                    onClick={handleAgain}
                    className="btn btn-primary ms-2"
                    value="Create again"
                  />
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AddContact;
