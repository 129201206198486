import React, {useCallback,useEffect,useState} from "react";
import {Link} from 'react-router-dom'
import {getAllContacts} from '../../../services/contact.service.js'
import {filterContacts} from '../../../utils/array.utils.js'
import Pagination from "../../NavBar/Pagination.js";
import style from './contactList.css';

const ContactList = () => {
  const [search, setSearch] = useState('');
  const [state,setState] = useState({
    books:[],
    allboks: [],
    isLoaded: false
  }) 

  const token = window.localStorage.getItem('token');
  const [offSet, setOffset] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [limit, setLimit] = React.useState(100);

  useEffect(()=>{
    setState({
      ...state,
      books: filterContacts(state.allboks, search),
      isLoaded: false
    })
  },[search])

  useEffect(()=>{
    getAllContacts(`?per_page=${limit}&page=${offSet}`).then(res=>{
      setState({
        books: res.data,
        allboks: res.data,
        isLoaded: false
      })

      setTotal(res.headers['x-wp-total']);
    })
      .catch(function (error) {
        console.log(error);
      });
  }, [offSet,limit]);

  let handleDelete = useCallback((bookId) => {
    var axios = require('axios');

    var config = {
      method: 'delete',
      url: `https://maiseguidores.com/wp-json/wp/v2/clientes/${bookId}`,
      headers: { 
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json'
      }
    };

    axios(config)
    .then(()=>{
      getAllContacts()
      .then(res =>{
        setState({
          books: res.data,
          allboks: res.data,
          isLoaded: false
        })
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [state]);

  const changeFilterName = ({ target: { value }}) => setSearch(value);
  const changeFilterAll = ({ target: { value }}) => setSearch('');
  const changeFilterButton = ({target}) => {
    setSearch(target.innerHTML)
  };

  return (
    <>
      <section className="contact-search p-3">
        <div className="container">
          <div className="grid">
            <div className="row">
              <div className="col">
                <p className="h3 fw-bold">
                  Contact Manager
                  <Link to={'/contacts/add'} className='btn btn-primary ms-2'> 
                  <i className="fa fa-plus-circle me-2"/>
                  New</Link>
                  </p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <form className="row">
                    <div className="col">
                      <div className="mb-4">
                        <input type="text" className="form-control dadada" onChange={changeFilterName} placeholder="Search Names"/>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section >
        <div>
          <ul className="d-flex ContactList__PageLimit">
            <li><button className="btn btn-primary" onClick={()=> {
              setOffset(1)
              setLimit(10) }}>10</button></li>
            <li><button className="btn btn-primary" onClick={()=>{ 
              setOffset(1)
              setLimit(25)}}>25</button></li>
            <li><button className="btn btn-primary" onClick={()=> {
              setOffset(1)
              setLimit(50)}}>50</button></li>
            <li><button className="btn btn-primary" onClick={()=> {
              setOffset(1)
              setLimit(75)}}>75</button></li>
            <li><button className="btn btn-primary" onClick={()=> {
              setOffset(1)
              setLimit(100)}}>100</button></li>
          </ul>
        </div>
      </section>
      <section className="ContactList__Container__filter">
        <form className="ContactList__Container__box ">
          <div className="d-flex ">
            <div className="p-1">
              <div className="">
                <p onClick={changeFilterAll} className="btn btn-outline-dark" placeholder="Adheart">All</p>
              </div>       
            </div>
            <div className="p-1">
              <div className="">
                <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Adheart</p>
              </div>       
            </div>
            <div className="p-1">
              <div className="">
                <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Adspy</p>
              </div>       
            </div>
            <div className="p-1 width__2">
              <div className="">
                <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Acesso Premium</p>
              </div>       
            </div>
          </div>
          <div className="d-flex ">
            <div className="p-1 width">
              <div className="">
                <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Mercado Pago</p>
              </div>       
            </div>
            <div className="p-1 Pix">
            <div className="">
              <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Pix</p>
            </div>       
          </div>
          </div>
        </form>
      </section>
      <section>
        <div className="d-flex">
                      {<Pagination 
                        limit={limit} 
                        total={total} 
                        offSet={offSet} 
                        setOffSet={setOffset} 
                      />}             
        </div>
      </section>
      <section  className="contact-list ">
        <div className="container">
          <div  className="row">
            {state.books.map(book=>(
                <div className="col-md-6" key={book.id}>
                  <div className="card my-2">
                    <div className="card-body">
                      <div className="row align-items-center d-flex justify-content-around p-2">
                        <div className="col-md-4">
                          <img src="https://cdn-icons-png.flaticon.com/512/219/219986.png" alt=""  className="contact-img"/>
                        </div>
                        <div className="col-md-7">
                          <ul className="list-group">
                            <li className="list-group-item list-group-item-action">
                              Name: <span className="fw-bold">{book.acf.name}</span>
                            </li>
                            <li className="list-group-item list-group-item-action">
                              Mobile: <span className="fw-bold">{book.acf.number}</span>
                            </li>
                            <li className="list-group-item list-group-item-action">
                              E-mail: <span className="fw-bold">{book.acf.email}</span>
                            </li>
                            <li className="list-group-item list-group-item-action">
                              Plano: <span className="fw-bold">{book.acf.title}</span>
                            </li>
                            <li className="list-group-item list-group-item-action">
                              Start: <span className="fw-bold">{book.acf.start}</span>
                            </li>
                            <li className="list-group-item list-group-item-action">
                              Status: <span className="fw-bold">{book.acf.status}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-1 d-flex flex-column align-items-center">
                          <Link to={`/contacts/view/${book.id}`} className="btn btn-warning my-1">
                            <i className="fa fa-eye" />
                          </Link>
                          <Link  to={`/contacts/edit/${book.id}`} className="btn btn-primary my-1">
                            <i className="fa fa-pen" />
                          </Link>
                          <button onClick={() => handleDelete(book.id)} className="btn btn-danger my-1">
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </section>

      <section className="ContactList__Container__filter">
        <form className="ContactList__Container__box ">
          <div className="d-flex ">
            <div className="p-1">
              <div className="">
                <p onClick={changeFilterAll} className="btn btn-outline-dark" placeholder="Adheart">All</p>
              </div>       
            </div>
            <div className="p-1">
              <div className="">
                <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Adheart</p>
              </div>       
            </div>
            <div className="p-1">
              <div className="">
                <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Adspy</p>
              </div>       
            </div>
            <div className="p-1 width__2">
              <div className="">
                <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Acesso Premium</p>
              </div>       
            </div>
          </div>
          <div className="d-flex ">
            <div className="p-1 width">
              <div className="">
                <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Mercado Pago</p>
              </div>       
            </div>
            <div className="p-1 Pix">
            <div className="">
              <p onClick={changeFilterButton} className="btn btn-outline-dark" placeholder="Adheart">Pix</p>
            </div>       
          </div>
          </div>
        </form>
      </section>
      <section >
        <div>
          <ul className="d-flex ContactList__PageLimit">
            <li><button className="btn btn-primary" onClick={()=> setLimit(10)}>10</button></li>
            <li><button className="btn btn-primary" onClick={()=> setLimit(25)}>25</button></li>
            <li><button className="btn btn-primary" onClick={()=> setLimit(50)}>50</button></li>
            <li><button className="btn btn-primary" onClick={()=> setLimit(75)}>75</button></li>
            <li><button className="btn btn-primary" onClick={()=> setLimit(100)}>100</button></li>
          </ul>
        </div>
      </section>
      <section>
        <div className="d-flex">
                      {<Pagination 
                        limit={limit} 
                        total={total} 
                        offSet={offSet} 
                        setOffSet={setOffset} 
                      />}             
        </div>
      </section>
    </>
  )
};

export default ContactList;

