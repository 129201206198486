import React,{useCallback} from "react";
import {Link, useParams, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import {getAllContacts} from '../../../services/contact.service.js';
import styles from './EditContact.css'

let EditContact = () => {
  let navigate = useNavigate()
  let contactId = useParams(); 
  const token = window.localStorage.getItem('token');


  const [content, setContent] = React.useState('');

  const [state,setState] = React.useState({
    clientes:{},
    isLoaded: false,
    content:''
  }) 


  React.useEffect(()=>{
    function componentDidMount() {
      getAllContacts(contactId.contactId)
      .then(res =>{
        const trashContent = res?.data?.content?.rendered;

        const regex = new RegExp("(?<=<p>).*(?=</p>)");

        const tent = trashContent.match(regex)?.[0]

        setState({
        clientes: res?.data?.acf,
        isLoaded: true,
        content: tent || ''
      })})
      .catch(err => console.log(err));
    }
    componentDidMount()  
  }, [contactId]);



  function updateInput(event){
    setState({ ...state, clientes:{
      ...state.clientes,
    [event.target.name]: event.target.value
    }})
  }

  let handleClick =  useCallback (async(e) => {
    e.preventDefault();
    try{
      await axios.post(`https://maiseguidores.com/wp-json/wp/v2/clientes/${contactId.contactId}`,{

        title: state?.clientes?.title,
        content: state?.content,
        excerpt: "opa",
        status: "publish",
        fields:{
          ...state.clientes
        },
        },{
          headers:{
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'
          }
        })

        navigate('/')

    }catch(e){
      console.log(e)
    }
  }, [state,content]);
  
  const trashContent = state?.content;

  const regex = new RegExp("(?<=<p>).*(?=</p>)");

  const t = trashContent;

  React.useEffect(()=>{
    setContent(t)
  },[])

  return (
    <React.Fragment>
      <section className="add-contact p-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="h4 text-primary fw-bold mb-2 ">Edit Contact</p>
              <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Observation: </label><label htmlFor="" className="EditContactWidth">
                <input
                    name="content"
                    value={state?.content}
                    onChange={(event)=> setState(prevState=> ({...prevState, content: event.target.value }))}
                    type="text"
                    className="fst-italic list-group-item EditContactWidth"
                    placeholder="Content"
                  /></label></p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <form action="">
                <div className="mb-2 ">
                  <p className="fst-italic list-group-item EditObservationContainer  "><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation ">Name: </label><label htmlFor="" className="EditContactWidth"><input
                      name="name"
                      value={state?.clientes?.name}
                      onChange={(event)=> updateInput(event)}
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    /></label></p>
                </div>
                <div className="mb-2">
                  <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Number: </label><label htmlFor="" className="EditContactWidth"><input
                    name="number"
                    value={state?.clientes?.number}
                    onChange={(event)=> updateInput(event)}
                    type="text" className="form-control" placeholder="Number" /></label></p>
                </div>
                <div className="mb-2">
                  <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Email: </label><label htmlFor="" className="EditContactWidth"><input
                    name="email"
                    value={state?.clientes?.email}
                    onChange={(event)=> updateInput(event)}
                    type="text" className="form-control" placeholder="E-mail" /></label></p>
                </div>
                <div className="mb-2">
                  <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Plane: </label><label htmlFor="" className="EditContactWidth"><input
                    name="title"
                    value={state?.clientes?.title}
                    onChange={(event)=> updateInput(event)}
                    type="text" className="form-control" placeholder="Plane" /></label></p>
                </div>
                <div className="mb-2">
                  <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Start: </label><label htmlFor="" className="EditContactWidth"><input
                    name="start"
                    value={state?.clientes?.start}
                    onChange={(event)=> updateInput(event)}
                    type="text" className="form-control" placeholder="Start" /></label></p>
                </div>
                <div className="mb-2">
                  <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Payment: </label><label htmlFor="" className="EditContactWidth"><input
                    name="payment"
                    value={state?.clientes?.payment}
                    onChange={(event)=> updateInput(event)}
                    type="text" className="form-control" placeholder="payment" /></label></p>
                </div>
                <div className="mb-2">
                  <p className="fst-italic list-group-item EditObservationContainer"><label htmlFor="" className="fst-italic mb-3 fw-bold EditContactObservation">Status: </label><label htmlFor="" className="EditContactWidth"><input
                    name="status"
                    value={state?.clientes?.status}
                    onChange={(event)=> updateInput(event)}
                    type="text" className="form-control" placeholder="Status" /></label></p>
                </div>
                <div className="mb-2">
                  <input onClick={handleClick} type="submit" className="btn btn-primary" value='Update' />
                  <Link to={'/contacts/list'} className='btn btn-dark ms-2'>Cancel</Link>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <img src="https://cdn-icons-png.flaticon.com/512/219/219986.png" className="contact-img" alt="" />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
};

export default EditContact;

