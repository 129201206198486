import React from "react";
import {Link, Navigate, useParams,useNavigate} from 'react-router-dom'
import axios from 'axios';
import {getAllContacts} from '../../../services/contact.service.js'
import styles from './ViewContact.css';

let ViewContact = () => {

  let navigate = useNavigate()

  let contactId = useParams(); 
  const token = window.localStorage.getItem('token');
  const [state,setState] = React.useState({
    clientes:[],
    isLoaded: false
  }) 


  React.useEffect(()=>{
    function componentDidMount() {
      getAllContacts(contactId.contactId).then(res => {
        const trashContent = res?.data?.content?.rendered;

        const regex = new RegExp("(?<=<p>).*(?=</p>)");

        const tent = trashContent.match(regex)?.[0]

        setState({
          clientes: res.data,
          isLoaded: true,
          content:  tent || ''
        })
      })
      .catch(err => console.log(err));
    }
    componentDidMount()  
  }, [contactId]);


  const trashContent = state?.clientes?.content?.rendered;

  const regex = new RegExp("(?<=<p>).*(?=</p>)");

  const content = trashContent?.match(regex)?.[0];
  
  function changePage(){
    navigate(`/contacts/edit/${contactId.contactId}/`)
  }

  return (
    <React.Fragment>
      <section className="view-contact-intro p-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="h3 text-warning fw-bold">View Contact <button onClick={changePage} className="btn btn-primary my-1"><i className="fa fa-pen" /></button></p>
              <p className="fst-italic list-group-item"><label htmlFor="" className="fst-italic mb-3 fw-bold">Observation: </label><p className="fst-italic list-group-item ViewContent">{content}</p></p>
            </div>
          </div>
        </div>
      </section>

      <section className="view-contact mt-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <img src="https://cdn-icons-png.flaticon.com/512/219/219986.png" className="contact-img" alt="" />
            </div>
            <div className="col-md-8">
            <ul className="list-group fst-italic list-group-item p-4">
              <li className="list-group-item list-group-item-action">
                Name: <span className="fw-bold">{(state.clientes.length !== 0 ) && state.clientes.acf.name }</span>
              </li>
              <li className="list-group-item list-group-item-action">
                number: <span className="fw-bold">{(state.clientes.length !== 0 ) && state.clientes.acf.number }</span>
              </li>
              <li className="list-group-item list-group-item-action">
                E-mail: <span className="fw-bold">{(state.clientes.length !== 0 ) && state.clientes.acf.email }</span>
              </li>
              <li className="list-group-item list-group-item-action">
                Plano: <span className="fw-bold">{(state.clientes.length !== 0 ) && state.clientes.acf.title }</span>
              </li>
              <li className="list-group-item list-group-item-action">
                start: <span className="fw-bold">{(state.clientes.length !== 0 ) && state.clientes.acf.start }</span>
              </li>
              <li className="list-group-item list-group-item-action">
                payment: <span className="fw-bold">{(state.clientes.length !== 0 ) && state.clientes.acf.payment }</span>
              </li>
              <li className="list-group-item list-group-item-action">
                Status: <span className="fw-bold">{(state.clientes.length !== 0 ) && state.clientes.acf.status }</span>
              </li>
            </ul>
            </div>
            <div className="row">
              <div className="col">
                <Link to={'/contacts/list'} className='btn btn-warning'>Back</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
};

export default ViewContact;

