import React from 'react'
import style from './Pagination.css'

const max_items = 7;
const max_left = (max_items - 1) / 2;


const Pagination = ({limit, total, offSet, setOffSet}) => {

  
  const current = offSet ? Math.round((offSet / limit) + 1)  : 1;
  const pages = Math.ceil( total / limit);
  const maxFirst = Math.max(pages - (max_items - 1), 1);
  const first = Math.min(Math.max(offSet - max_left, 1),maxFirst);

  function onPageChange(page){
    setOffSet((page))
  }

  return (
    <ul className='paginatio'>
      <li className='Pagination__before '>
        <button 
        className='btn btn-warning'
        onClick={()=> setOffSet(offSet - 1)}
        disabled={offSet === 1}
        >
          Before
        </button>
      </li>
      {Array.from({length: Math.min(pages,max_items)})
        .map((_,index) => index + first )
        .map((page) => (
          <li key={page} className='Pagination__pages '>
            <button 
            onClick={() => {
              onPageChange(page)
            }} 
            className={page === offSet ? 'paginatio__item--active btn btn-warning' : 'btn btn-warning'}>
              {page} 
            </button>  
          </li>
        ))}
      <li className='Pagination__next '>
        <button 
        className='btn btn-warning'
        onClick={()=> onPageChange(offSet + 1)}
        disabled={offSet === pages}
        >
          Next
        </button>
      </li>
    </ul>
  )
}

export default Pagination