import React from 'react';
import './App.css';
import ContactList from './components/contacts/ContactList/ContactList'
import AddContact from './components/contacts/AddContact/AddContact'
import ViewContact from './components/contacts/ViewContact/ViewContact';
import {Routes, Route, Navigate} from 'react-router-dom'
import NavBar from './components/NavBar/NavBar'
import EditContact from './components/contacts/EditContact/EditContact';
import Login from './Login/Login';
import IsAuth from './Login/IsAuth';


let App = () => {
  const token = window.localStorage.getItem('token');

  return (
    <React.Fragment>
      <NavBar />
      <Routes>
        <Route path={'/'} element={<IsAuth><Navigate to={'/contacts/list'} /></IsAuth>} />
        <Route path={'/contacts/list'} element={<IsAuth><ContactList /></IsAuth>} />
        <Route path={'/contacts/login'} element={<Login />} />
        <Route path={'/contacts/add'} element={<IsAuth><AddContact /></IsAuth> } />
        <Route path={'/contacts/view/:contactId'} element={<IsAuth><ViewContact /></IsAuth>} />
        <Route path={'/contacts/edit/:contactId'} element={<EditContact />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
