import React from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {

  const [username,setUsername] = React.useState('');
  const [password,setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const navigate = useNavigate();

  React.useEffect(()=>{
    async function autoLogin(){
      const token = window.localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.post(`https://maiseguidores.com/wp-json/jwt-auth/v1/token/validate`,{
          title:'',
          headers:{
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'
          }
        })
          if(!response.ok) throw new Error('Token inválido');
          } catch(err){
        } finally{
          
        }
      } 
    }
    autoLogin()
  }, [] );

  async function handleSubmit(event){
    event.preventDefault();
    try{
      const tokenRes = await axios.post(`https://maiseguidores.com/wp-json/jwt-auth/v1/token`,{
        username: username,
        password: password,
        headers:{
            'Content-Type': 'application/json'
          }
      })
      const token = tokenRes.data.token;
      window.localStorage.setItem('token',`${token}`);
      navigate('/')
    } catch(e){
      const login = document.querySelector('.loginLogin')
      const password = document.querySelector('.loginPassword')

      login.focus();
      password.focus();
      setError('Ops.. Acesso não autorizado')
    }
  }

  return (
    <section className='LoginContainer'>
      <h1 className='LoginTitle'>Login</h1>
      <form onSubmit={handleSubmit} action="" className="">
        <input type="text" value={username} className='loginLogin form-control mb-4 ' onChange={({target})=> setUsername(target.value)} />
        <input type="password" value={password} className='loginPassword form-control mb-4 ' onChange={({target})=> setPassword(target.value)} />
        {error && <p style={{color: '#f31', margin:'1rem 0'}} >{error}</p>}
        <button className="btn btn-warning my-1 LoginButton "  >Entrar</button>
      </form>
    </section>
  )
}

export default Login